<template>
  <div class="body22">
    <div class="back">
      <div class="logo">
        <img class="large" :src="$tenant.bigLogo" style="height: 50px"/>
      </div>
      <div  class="content1">
        <el-divider content-position="left"></el-divider>
        <a href="/pdf/音视频终端ESDT405S-X说明书V2.0.pdf">音视频终端ESDT405S-X 说明书V2.0.pdf</a>
        <el-divider content-position="left"></el-divider>
        <a href="/pdf/通话终端ESDT400SA-X说明书V2.0.pdf">通话终端ESDT400SA-X 说明书V2.0.pdf</a>
        <el-divider content-position="left"></el-divider>
      </div>
    </div>
    <div class="footer">
    </div>
  </div>
</template>

<script>
  const moduleName = "workOrders";
  import {Base64} from "js-base64";

  export default {
    components: {},
    data() {
      return {
        copyright: window.config.copyright,
        showContent: false,
        showMsg: "",
        stepActive: 1,
        display1: "block",
        display2: "block",
        display3: "block",
        activeTab: "first",
        tableData1: [],
        tableData2: [],
        total1: 0,
        total2: 0,
        currentPage1: 1,
        currentPage2: 1,
      };
    },
    mounted() {

    },
    methods: {
    },
  };
</script>
<style lang="scss" scoped>

.body22 {
  background-color: #f2f2f2;
  height: 100%;
  overflow-y: scroll;

  .back {
    width: 100%;

    .logo {
      width: 100%;
      margin: 0 auto;
    }

    .content {
      width: 100%;
      background-color: white;
      margin: 0 auto;
      border: solid 1px #DCDFE6;
      padding-bottom: 100px;
    }

    .content1 {
      width: 100%;
      background-color: white;
      margin: 0 auto;
      border: solid 1px #DCDFE6;
      padding-bottom: 100px;
      text-align: center;
    }
  }

  .footer {
    color: #FFF;
    background-color: #404040;
    position: fixed;
    text-align: center;
    width: 100%;
    left: 0;
    bottom: 0px;
    z-index: 100;
  }
}


span {
  color: #606266;
}

.vm-separate {
  font-size: 12px;
  margin-bottom: 6px;
  margin-left: 6px;
  margin-right: 6px;
}

ul {
  white-space: nowrap;
  width: 92%;
}

li {
  list-style-type: none;
  display: inline;
}

p {
  margin: 0;
}

.title {
  text-align: center;
  font-weight: bold;
  font-size: 16px;
  color: #5a9d11;
  margin-left: -10px;
}

.title_gray {
  text-align: center;
  font-weight: bold;
  font-size: 16px;
  color: #999999;
  margin-left: -10px;
}

.date {
  font-size: 12px;
  color: #666666;
  text-align: center;
  margin-top: 5px;
}

.childTable {
  width: 100%;
  border-collapse: collapse;

  td {
    border: 1px solid #D8DCE5;
    padding: 5px 10px;
    background-color: #FAFAFA;
  }

  th {
    font-weight: 500;
    text-align: center;
    border: 1px solid #D8DCE5;
    padding: 8px 10px;
    background-color: #EEF2F7;
  }
}
</style>
